import React, { useEffect } from "react";
import "./Home.css";
import { Button, Footer, Header, Loading } from "../../components";
import qr from "../../assets/qr.webp";
import bitcoin from "../../assets/bitcoin.svg";
import description from "../../assets/description.svg";
import trading from "../../assets/hand3.png";
import invest from "../../assets/invest.svg";
import track from "../../assets/track.svg";
import { AiOutlineArrowRight } from "react-icons/ai";
import { useState } from "react";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";
import customer from "../../assets/customer.jpg";
import customer1 from "../../assets/customer1.jpg";
import { useNavigate } from "react-router-dom";
import Motion from "../Motion";
import MotionX from "../MotionX";
import MotionRight from "../MotionRight";
import { deposits, withdrawals } from "../../utils/data";
import axios from "axios";
import { useGlobalContext } from "../../context/context";
import { toast } from "react-toastify";

const Home = () => {
  const name = "Profitmonitoring";
  const [activeFaq, setActiveFaq] = useState(0);
  const [active, setActive] = useState(1);
  const symbol = "R";
  const { baseUrl } = useGlobalContext();

  const navigate = useNavigate();

  const toggleActiveFaq = (e) => {
    if (e === "1") {
      setActiveFaq(1);
    } else if (e === "2") {
      setActiveFaq(2);
    } else if (e === "3") {
      setActiveFaq(3);
    } else if (e === "4") {
      setActiveFaq(4);
    } else if (e === "5") {
      setActiveFaq(5);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (active === 3) {
        setActive(1);
      } else {
        setActive(active + 1);
      }
    }, 2000);
  }, [active]);

  useEffect(() => {
    axios
      .get(`${baseUrl}transfer`)
      .then((data) => {})
      .catch((error) => {});
  }, []);

  return (
    <section className="home_section">
      <Header />
      <div className="home_sect_one">
        <div className={`${active === 1 ? "active_slide" : ""}`}>
          <Motion>
            <h2 className="home_title">Welcome to {name}</h2>
          </Motion>
          <MotionX>
            <p className="home_paragraph">
              Are you ready to step into the thrilling world of cryptocurrency
              trading?
            </p>
          </MotionX>
          <MotionRight>
            <Button
              title={"Sign up"}
              background={"#001e88"}
              color={"white"}
              width={170}
              height={45}
              action={() => navigate("/auth/register")}
            />
          </MotionRight>
        </div>
        <div className={`${active === 2 ? "active_slide" : ""}`}>
          <MotionX>
            <h2 className="home_title">Secured And Safe</h2>
          </MotionX>
          <Motion>
            <p className="home_paragraph">
              We offer the most secured crpyto trading and investment solution
              on the globe
            </p>
          </Motion>
          <Motion>
            <Button
              title={"Sign up"}
              background={"#001e88"}
              color={"white"}
              width={170}
              height={45}
              action={() => navigate("/auth/register")}
            />
          </Motion>
        </div>
        <div className={`${active === 3 ? "active_slide" : ""}`}>
          <MotionRight>
            <h2 className="home_title">Financial Growth</h2>
          </MotionRight>
          <Motion>
            <p className="home_paragraph">
              We utilize market data to derive huge revenue in the
              cryptocurrency ecosystem
            </p>
          </Motion>
          <MotionX>
            <Button
              title={"Sign up"}
              background={"#001e88"}
              color={"white"}
              width={170}
              height={45}
              action={() => navigate("/auth/register")}
            />
          </MotionX>
        </div>
      </div>
      <div className="home_sect_two">
        <div>
          <MotionRight>
            <Button
              action={() => ""}
              border={"1px solid #001e88"}
              background={"white"}
              title={"STEP 1"}
              fontWeight={400}
              color={"#001e88"}
              fontSize={16}
              width={120}
            />
          </MotionRight>
          <Motion>
            <h2 className="home_sect_two_title">Welcome to {name}</h2>
          </Motion>
          <div className="home_sect_two_image_con">
            <MotionRight>
              <img src={qr} alt="" />
            </MotionRight>
          </div>
        </div>
        <div>
          <Motion>
            <Button
              action={() => ""}
              border={"1px solid #001e88"}
              background={"white"}
              title={"STEP 2"}
              fontWeight={400}
              color={"#001e88"}
              fontSize={16}
              width={120}
            />
          </Motion>
          <MotionX>
            <h2 className="home_sect_two_title">Explore and decide</h2>
          </MotionX>
          <div className="home_sect_two_image_con">
            <Motion>
              <img src={bitcoin} alt="" />
            </Motion>
          </div>
        </div>
        <div>
          <MotionX>
            <Button
              action={() => ""}
              border={"1px solid #001e88"}
              background={"white"}
              title={"STEP 3"}
              fontWeight={400}
              color={"#001e88"}
              fontSize={16}
              width={120}
            />
          </MotionX>
          <Motion>
            <h2 className="home_sect_two_title">Invest from £10</h2>
          </Motion>
          <div className="home_sect_two_image_con">
            <MotionRight>
              <img src={description} alt="" />
            </MotionRight>
          </div>
        </div>
      </div>
      <div className="home_sect_three">
        <Motion>
          <div className="sect_three_center">
            <p className="home_paragraph ">Features</p>
          </div>
        </Motion>
        <MotionX>
          <div className="sect_three_center">
            <h1 className="home_title bold_header_text">
              With you every step of the way
            </h1>
          </div>
        </MotionX>
        <MotionRight>
          <div className="sect_three_center">
            <p className="home_paragraph about_p_text">
              Investment decisions are important, but they don't have to be
              hard.
            </p>
          </div>
        </MotionRight>
        <div className="explore_sect">
          <div className="explore_sect_text_container">
            <div>
              <MotionX>
                <h1 className="home_title">Explore</h1>
              </MotionX>
              <Motion>
                <p className="home_paragraph">
                  Get simple information so you can make the best decisions
                </p>
              </Motion>
            </div>
            <div className="explore_sect_btn_container">
              <Motion>
                <p className="home_paragraph"> Learn more about crypto</p>
              </Motion>
              <MotionX>
                <Button
                  title={<AiOutlineArrowRight color="#2639f2" />}
                  action={() => ""}
                />
              </MotionX>
            </div>
          </div>
          <div className="explore_sect_image_sect">
            <MotionRight>
              <img src={trading} alt="" />
            </MotionRight>
          </div>
        </div>
        <div className="how_to_invest_sect">
          <div className="invest_items">
            <MotionX>
              <h1 className="home_title ">Invest</h1>
            </MotionX>
            <Motion>
              <p className="home_paragraph">
                When you're ready, invest at the tap of a button.
              </p>
            </Motion>
            <Motion>
              <img src={invest} alt="" />
            </Motion>
          </div>
          <div className="invest_items">
            <MotionRight>
              <h1 className="home_title">Track</h1>
            </MotionRight>
            <Motion>
              <p className="home_paragraph">
                Easily keep an eye on the value of your crypto.
              </p>
            </Motion>
            <Motion>
              <img src={track} alt="" />
            </Motion>
          </div>
        </div>
      </div>
      <div className="faq">
        <Motion>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h1 className="home_title bold_header_text">
              Frequently Asked Questions
            </h1>
          </div>
        </Motion>
        <div className="faq_items_sect">
          <div className="faq_items">
            <div className="faqs">
              <MotionX>
                <p
                  className="home_paragraph"
                  id="1"
                  onClick={(e) => toggleActiveFaq(e.target.id)}
                >
                  What cryptocurrencies does {name} offer in the UK?
                </p>
              </MotionX>
              {activeFaq === 1 ? (
                <MdOutlineKeyboardArrowUp
                  id="1"
                  onClick={(e) => toggleActiveFaq(e.target.id)}
                  size={24}
                  color="#001e88"
                />
              ) : (
                <MdOutlineKeyboardArrowDown
                  size={24}
                  id="1"
                  onClick={(e) => toggleActiveFaq(e.target.id)}
                  color="#001e88"
                />
              )}
            </div>
            <Motion>
              <p
                className={`faq_text ${
                  activeFaq === 1 ? "active_faq_text" : ""
                }`}
              >
                The following cryptocurrencies are available to buy with GBP:
                Bitcoin, Ethereum, Bitcoin Cash, USDC, XRP, Litecoin, Chainlink,
                Uniswap, Cardano, Solana and Avalanche.
              </p>
            </Motion>
          </div>
          <div className="faq_items">
            <div className="faqs">
              <Motion>
                <p
                  id="2"
                  onClick={(e) => toggleActiveFaq(e.target.id)}
                  className="home_paragraph"
                >
                  How do I buy cryptocurrency with {name} using Ethereum, Usdt,
                  Bitcoin, etc?
                </p>
              </Motion>
              {activeFaq === 2 ? (
                <MdOutlineKeyboardArrowUp
                  id="2"
                  onClick={(e) => toggleActiveFaq(e.target.id)}
                  size={24}
                  color="#001e88"
                />
              ) : (
                <MdOutlineKeyboardArrowDown
                  size={24}
                  id="2"
                  onClick={(e) => toggleActiveFaq(e.target.id)}
                  color="#001e88"
                />
              )}
            </div>
            <p
              className={`faq_text ${activeFaq === 2 ? "active_faq_text" : ""}`}
            >
              The following cryptocurrencies are available to buy in the UK with
              GBP: Bitcoin, Ethereum, Bitcoin Cash, USDC, XRP, Litecoin,
              Chainlink, Uniswap, Cardano, Solana and Avalanche.
            </p>
          </div>
          <div className="faq_items">
            <div className="faqs">
              <p
                id="3"
                onClick={(e) => toggleActiveFaq(e.target.id)}
                className="home_paragraph"
              >
                Is there a limit to how much I can buy in the UK?
              </p>
              {activeFaq === 3 ? (
                <MdOutlineKeyboardArrowUp
                  id="2"
                  onClick={(e) => toggleActiveFaq(e.target.id)}
                  size={24}
                  color="#001e88"
                />
              ) : (
                <MdOutlineKeyboardArrowDown
                  size={24}
                  id="3"
                  onClick={(e) => toggleActiveFaq(e.target.id)}
                  color="#001e88"
                />
              )}
            </div>
            <MotionX>
              <p
                className={`faq_text ${
                  activeFaq === 3 ? "active_faq_text" : ""
                }`}
              >
                Other than your verification level limits, there are no
                additional deposit limits. Your bank might have payment limits,
                and if they do, we’d suggest getting in touch with them.
              </p>
            </MotionX>
          </div>
          <div className="faq_items">
            <div className="faqs">
              <Motion>
                <p
                  id="4"
                  onClick={(e) => toggleActiveFaq(e.target.id)}
                  className="home_paragraph"
                >
                  How do I create an account?
                </p>
              </Motion>
              {activeFaq === 4 ? (
                <MdOutlineKeyboardArrowUp
                  id="4"
                  onClick={(e) => toggleActiveFaq(e.target.id)}
                  size={24}
                  color="#001e88"
                />
              ) : (
                <MdOutlineKeyboardArrowDown
                  size={24}
                  id="4"
                  onClick={(e) => toggleActiveFaq(e.target.id)}
                  color="#001e88"
                />
              )}
            </div>
            <p
              className={`faq_text ${activeFaq === 4 ? "active_faq_text" : ""}`}
            >
              Creating an account is a straightforward process. Click the create
              account button or sign in to create one
            </p>
          </div>
        </div>
      </div>
      <div className="customers_review_sect">
        <MotionRight>
          <h1 className="bold_header_text home_title">
            What People Are Saying
          </h1>
        </MotionRight>
        <Motion>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p className="home_paragraph">
              We are trusted by thousands of users across the globe.
            </p>
          </div>
        </Motion>
        <div className="customers_review_container">
          <div className="customers_review_container_item_one">
            <Motion>
              <p className="shadow_text">
                I want to say a big thank you to {name}. Just got my profit of
                $7500 in my Bank account. This is indeed a trust worthy platform
                to invest
              </p>
            </Motion>
            <div className="customer_review_details">
              <img src={customer} alt="" />
              <div>
                <h3>- Carly Jones</h3>
                <p>Verified Client</p>
              </div>
              <p></p>
            </div>
          </div>
          <div className="customers_review_container_item_one">
            <MotionX>
              <p className="shadow_text">
                It's really wonderful. I would also like to say thank you to all
                your staff. {name} did exactly what you said it does.
              </p>
            </MotionX>
            <div className="customer_review_details">
              <img src={customer1} alt="" />
              <div>
                <h3>- Cynthia Jones</h3>
                <p>Verified Client</p>
              </div>
              <p></p>
            </div>
          </div>
        </div>
      </div>
      <div className="transaction_reviews">
        <div className="withdrawals_container">
          <Motion>
            <h1 className="home_title bold_header_text"> Recent Deposits</h1>
          </Motion>
          <Motion>
            <div className="transaction_table">
              <div className="home_transaction_table_header">
                <div className="sn">
                  <h3>#</h3>
                </div>
                <div className="home_type">
                  <h3>Type</h3>
                </div>
                <div className="home_name">
                  <h3>name</h3>
                </div>
                <div className="home_amount">
                  <h3>Amount</h3>
                </div>
                <div className="home_status">
                  <h3>Status</h3>
                </div>
              </div>
              {deposits.map((item, index) => {
                const { name, type, amount, status, symbol } = item;
                return (
                  <div key={index} className="home_transaction_table_body">
                    <div className="sn">
                      <p>{index + 1}</p>
                    </div>
                    <div className="home_type">
                      <p>{type}</p>
                    </div>
                    <div className="home_name">
                      <p>{name}</p>
                    </div>
                    <div className="home_amount">
                      <p>
                        {symbol ? symbol : "R"}
                        {amount}
                      </p>
                    </div>
                    <div className="home_status">
                      <Button
                        action={() => ""}
                        title={status ? "approved" : "pending"}
                        color={status ? "#27AE61" : "#999DA1"}
                        border={`1px solid ${status ? "#27AE61" : "#999DA1"}`}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </Motion>
        </div>
        <div className="withdrawals_container">
          <MotionX>
            <h1 className="home_title bold_header_text"> Recent Withdrawals</h1>
          </MotionX>
          <MotionRight>
            <div className="transaction_table">
              <div className="home_transaction_table_header">
                <div className="sn">
                  <h3>#</h3>
                </div>
                <div className="home_type">
                  <h3>Type</h3>
                </div>
                <div className="home_name">
                  <h3>name</h3>
                </div>
                <div className="home_amount">
                  <h3>Amount</h3>
                </div>
                <div className="home_status">
                  <h3>Status</h3>
                </div>
              </div>
              {withdrawals.map((item, index) => {
                const { status, amount, type, name, symbol } = item;
                return (
                  <div key={index} className="home_transaction_table_body">
                    <div className="sn">
                      <p>{index + 1}</p>
                    </div>
                    <div className="home_type">
                      <p>{type}</p>
                    </div>
                    <div className="home_name">
                      <p>{name}</p>
                    </div>
                    <div className="home_amount">
                      <p>
                        {symbol ? symbol : "R"}
                        {amount}
                      </p>
                    </div>
                    <div className="home_status">
                      <Button
                        action={() => ""}
                        title={status ? "approved" : "pending"}
                        color={status ? "#27AE61" : "#999DA1"}
                        border={`1px solid ${status ? "#27AE61" : "#999DA1"}`}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </MotionRight>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default Loading(Home);
