import { AiFillHome, AiOutlineUser } from "react-icons/ai";
import {
  MdOutlineDescription,
  MdOutlinePriceCheck,
  MdPlace,
} from "react-icons/md";
import { FaUser, FaWallet } from "react-icons/fa";

export const navItems = [
  {
    name: "Resources",
    items: [{ name: "Home", path: "", src: <AiFillHome size={20} /> }],
  },
  {
    name: "Reserves",
    items: [
      {
        name: "Proof Of Reserve",
        path: "proof-of-reserve",
        src: <MdPlace size={20} />,
      },
    ],
  },
  {
    name: "account",
    items: [
      { name: "Sign Up", path: "auth/register", src: <FaUser size={20} /> },
      { name: "Sign In", path: "auth/login", src: <AiOutlineUser size={20} /> },
    ],
  },
  {
    name: "products",
    items: [
      {
        name: "Wallet",
        path: "wallet",
        src: <FaWallet size={20} />,
      },
      {
        name: "Exchange",
        path: "exchange",
        src: <MdOutlinePriceCheck size={20} />,
      },
    ],
  },
  {
    name: "about",
    items: [
      {
        name: "About",
        path: "about",
        src: <MdOutlineDescription size={20} />,
      },
    ],
  },
];

export const withdrawals = [
  {
    name: "Liberty Rothstein",
    type: "Bitcoin",
    amount: 5000,
    status: true,
    symbol: "₹",
  },
  {
    name: "Teodoro Scalese",
    type: "Ethereum",
    amount: 18000,
    status: true,
  },
  {
    name: "Karlyn Schettig",
    type: "Bitcoin",
    amount: 17500,
    status: false,
    symbol: "$",
  },
  {
    name: "Kerrie Juckett",
    type: "Ethereum",
    amount: 50000,
    status: false,
    symbol: "₹",
  },
  {
    name: "Juliet Austin",
    type: "USDT",
    amount: 20000,
    status: true,
    symbol: "$",
  },
  {
    name: "Jane Appeal",
    type: "USDT",
    amount: 25000,
    status: true,
    symbol: "₱",
  },
  {
    name: "David Julius",
    type: "Ethereum",
    amount: 30000,
    status: true,
  },
  {
    name: "Jennifer Jane",
    type: "USDT",
    amount: 30000,
    status: false,
    symbol: "₱",
  },
];

export const deposits = [
  {
    name: "Alice Johnson",
    type: "Ethereum",
    status: true,
    amount: 100000,
    symbol: "₹",
  },
  {
    name: "Bob Smith",
    type: "USDT",
    status: false,
    amount: 5000000,
    symbol: "₱",
  },
  {
    name: "Hannah Clark",
    type: "Ethereum",
    status: true,
    amount: 750000,
  },
  {
    name: "Charlie Brown",
    type: "USDT",
    status: false,
    amount: 1200000,
  },
  {
    name: "David Lee",
    type: "Bitcoin",
    status: true,
    amount: 2000000,
  },
  {
    name: "Eve Davis",
    type: "Bitcoin",
    status: true,
    amount: 3000000,
    symbol: "₱",
  },
  {
    name: "Frank White",
    type: "Ethereum",
    status: false,
    amount: 900000,
    symbol: "$",
  },
  {
    name: "Grace Taylor",
    type: "Bitcoin",
    status: true,
    amount: 6000000,
    symbol: "₹",
  },
];

export const countries = [
  { country: "Afghanistan", currency_symbol: "؋" },
  { country: "Albania", currency_symbol: "L" },
  { country: "Algeria", currency_symbol: "د.ج" },
  { country: "Andorra", currency_symbol: "€" },
  { country: "Angola", currency_symbol: "Kz" },
  { country: "Antigua and Barbuda", currency_symbol: "$" },
  { country: "Argentina", currency_symbol: "$" },
  { country: "Armenia", currency_symbol: "դր" },
  { country: "Australia", currency_symbol: "$" },
  { country: "Austria", currency_symbol: "€" },
  { country: "Azerbaijan", currency_symbol: "₼" },
  { country: "Bahamas", currency_symbol: "$" },
  { country: "Bahrain", currency_symbol: ".د.ب" },
  { country: "Bangladesh", currency_symbol: "৳" },
  { country: "Barbados", currency_symbol: "$" },
  { country: "Belarus", currency_symbol: "Br" },
  { country: "Belgium", currency_symbol: "€" },
  { country: "Belize", currency_symbol: "$" },
  { country: "Benin", currency_symbol: "₣" },
  { country: "Bhutan", currency_symbol: "Nu" },
  { country: "Bolivia", currency_symbol: "Bs" },
  { country: "Bosnia and Herzegovina", currency_symbol: "KM" },
  { country: "Botswana", currency_symbol: "P" },
  { country: "Brazil", currency_symbol: "R$" },
  { country: "Brunei", currency_symbol: "B$" },
  { country: "Bulgaria", currency_symbol: "лв" },
  { country: "Burkina Faso", currency_symbol: "₣" },
  { country: "Burundi", currency_symbol: "FBu" },
  { country: "Cabo Verde", currency_symbol: "Esc" },
  { country: "Cambodia", currency_symbol: "៛" },
  { country: "Cameroon", currency_symbol: "₣" },
  { country: "Canada", currency_symbol: "$" },
  { country: "Central African Republic", currency_symbol: "₣" },
  { country: "Chad", currency_symbol: "₣" },
  { country: "Chile", currency_symbol: "$" },
  { country: "China", currency_symbol: "¥" },
  { country: "Colombia", currency_symbol: "$" },
  { country: "Comoros", currency_symbol: "CF" },
  { country: "Congo (Congo-Brazzaville)", currency_symbol: "₣" },
  { country: "Congo (Congo-Kinshasa)", currency_symbol: "FC" },
  { country: "Costa Rica", currency_symbol: "₡" },
  { country: "Croatia", currency_symbol: "kn" },
  { country: "Cuba", currency_symbol: "₱" },
  { country: "Cyprus", currency_symbol: "€" },
  { country: "Czech Republic", currency_symbol: "Kč" },
  { country: "Denmark", currency_symbol: "kr" },
  { country: "Djibouti", currency_symbol: "Fdj" },
  { country: "Dominica", currency_symbol: "$" },
  { country: "Dominican Republic", currency_symbol: "RD$" },
  { country: "East Timor", currency_symbol: "$" },
  { country: "Ecuador", currency_symbol: "$" },
  { country: "Egypt", currency_symbol: "£" },
  { country: "El Salvador", currency_symbol: "$" },
  { country: "Equatorial Guinea", currency_symbol: "₣" },
  { country: "Eritrea", currency_symbol: "Nfk" },
  { country: "Estonia", currency_symbol: "€" },
  { country: "Eswatini", currency_symbol: "L" },
  { country: "Ethiopia", currency_symbol: "ታ" },
  { country: "Fiji", currency_symbol: "FJ$" },
  { country: "Finland", currency_symbol: "€" },
  { country: "France", currency_symbol: "€" },
  { country: "Gabon", currency_symbol: "₣" },
  { country: "Gambia", currency_symbol: "D" },
  { country: "Georgia", currency_symbol: "₾" },
  { country: "Germany", currency_symbol: "€" },
  { country: "Ghana", currency_symbol: "₵" },
  { country: "Greece", currency_symbol: "€" },
  { country: "Grenada", currency_symbol: "$" },
  { country: "Guatemala", currency_symbol: "Q" },
  { country: "Guinea", currency_symbol: "FG" },
  { country: "Guinea-Bissau", currency_symbol: "₣" },
  { country: "Guyana", currency_symbol: "G$" },
  { country: "Haiti", currency_symbol: "G" },
  { country: "Honduras", currency_symbol: "L" },
  { country: "Hungary", currency_symbol: "Ft" },
  { country: "Iceland", currency_symbol: "kr" },
  { country: "India", currency_symbol: "₹" },
  { country: "Indonesia", currency_symbol: "Rp" },
  { country: "Iran", currency_symbol: "﷼" },
  { country: "Iraq", currency_symbol: "ع.د" },
  { country: "Ireland", currency_symbol: "€" },
  { country: "Israel", currency_symbol: "₪" },
  { country: "Italy", currency_symbol: "€" },
  { country: "Jamaica", currency_symbol: "$" },
  { country: "Japan", currency_symbol: "¥" },
  { country: "Jordan", currency_symbol: "د.ا" },
  { country: "Kazakhstan", currency_symbol: "₸" },
  { country: "Kenya", currency_symbol: "KSh" },
  { country: "Kiribati", currency_symbol: "$" },
  { country: "Korea, North", currency_symbol: "₩" },
  { country: "Korea, South", currency_symbol: "₩" },
  { country: "Kuwait", currency_symbol: "د.ك" },
  { country: "Kyrgyzstan", currency_symbol: "с" },
  { country: "Laos", currency_symbol: "₭" },
  { country: "Latvia", currency_symbol: "€" },
  { country: "Lebanon", currency_symbol: "ل.ل" },
  { country: "Lesotho", currency_symbol: "L" },
  { country: "Liberia", currency_symbol: "L$" },
  { country: "Libya", currency_symbol: "د.ل" },
  { country: "Liechtenstein", currency_symbol: "CHF" },
  { country: "Lithuania", currency_symbol: "€" },
  { country: "Luxembourg", currency_symbol: "€" },
  { country: "Madagascar", currency_symbol: "Ar" },
  { country: "Malawi", currency_symbol: "MK" },
  { country: "Malaysia", currency_symbol: "RM" },
  { country: "Maldives", currency_symbol: "Rf" },
  { country: "Mali", currency_symbol: "₣" },
  { country: "Malta", currency_symbol: "€" },
  { country: "Marshall Islands", currency_symbol: "$" },
  { country: "Mauritania", currency_symbol: "UM" },
  { country: "Mauritius", currency_symbol: "₨" },
  { country: "Mexico", currency_symbol: "$" },
  { country: "Micronesia", currency_symbol: "$" },
  { country: "Moldova", currency_symbol: "lei" },
  { country: "Monaco", currency_symbol: "€" },
  { country: "Mongolia", currency_symbol: "₮" },
  { country: "Montenegro", currency_symbol: "€" },
  { country: "Morocco", currency_symbol: "د.م" },
  { country: "Mozambique", currency_symbol: "MT" },
  { country: "Myanmar (Burma)", currency_symbol: "Ks" },
  { country: "Namibia", currency_symbol: "N$" },
  { country: "Nauru", currency_symbol: "$" },
  { country: "Nepal", currency_symbol: "₨" },
  { country: "Netherlands", currency_symbol: "€" },
  { country: "New Zealand", currency_symbol: "$" },
  { country: "Nicaragua", currency_symbol: "C$" },
  { country: "Niger", currency_symbol: "₣" },
  { country: "Nigeria", currency_symbol: "₦" },
  { country: "North Macedonia", currency_symbol: "ден" },
  { country: "Norway", currency_symbol: "kr" },
  { country: "Oman", currency_symbol: "ر.ع." },
  { country: "Pakistan", currency_symbol: "₨" },
  { country: "Palau", currency_symbol: "$" },
  { country: "Panama", currency_symbol: "B/" },
  { country: "Papua New Guinea", currency_symbol: "K" },
  { country: "Paraguay", currency_symbol: "₲" },
  { country: "Peru", currency_symbol: "S/" },
  { country: "Philippines", currency_symbol: "₱" },
  { country: "Poland", currency_symbol: "zł" },
  { country: "Portugal", currency_symbol: "€" },
  { country: "Qatar", currency_symbol: "ر.ق" },
  { country: "Romania", currency_symbol: "lei" },
  { country: "Russia", currency_symbol: "₽" },
  { country: "Rwanda", currency_symbol: "FRw" },
  { country: "Saint Kitts and Nevis", currency_symbol: "$" },
  { country: "Saint Lucia", currency_symbol: "$" },
  { country: "Saint Vincent and the Grenadines", currency_symbol: "$" },
  { country: "Samoa", currency_symbol: "T" },
  { country: "San Marino", currency_symbol: "SML" },
  { country: "Sao Tome and Principe", currency_symbol: "Db" },
  { country: "Saudi Arabia", currency_symbol: "ر.س" },
  { country: "Senegal", currency_symbol: "₣" },
  { country: "Serbia", currency_symbol: "дин" },
  { country: "Seychelles", currency_symbol: "₨" },
  { country: "Sierra Leone", currency_symbol: "Le" },
  { country: "Singapore", currency_symbol: "$" },
  { country: "Slovakia", currency_symbol: "€" },
  { country: "Slovenia", currency_symbol: "€" },
  { country: "Solomon Islands", currency_symbol: "$" },
  { country: "Somalia", currency_symbol: "S" },
  { country: "South Africa", currency_symbol: "R" },
  { country: "South Sudan", currency_symbol: "£" },
  { country: "Spain", currency_symbol: "€" },
  { country: "Sri Lanka", currency_symbol: "Rs" },
  { country: "Sudan", currency_symbol: "£" },
  { country: "Suriname", currency_symbol: "$" },
  { country: "Sweden", currency_symbol: "kr" },
  { country: "Switzerland", currency_symbol: "CHF" },
  { country: "Syria", currency_symbol: "£" },
  { country: "Taiwan", currency_symbol: "NT$" },
  { country: "Tajikistan", currency_symbol: "SM" },
  { country: "Tanzania", currency_symbol: "TSh" },
  { country: "Thailand", currency_symbol: "฿" },
  { country: "Togo", currency_symbol: "₣" },
  { country: "Tonga", currency_symbol: "T$" },
  { country: "Trinidad and Tobago", currency_symbol: "TT$" },
  { country: "Tunisia", currency_symbol: "د.ت" },
  { country: "Turkey", currency_symbol: "₺" },
  { country: "Turkmenistan", currency_symbol: "m" },
  { country: "Tuvalu", currency_symbol: "$" },
  { country: "Uganda", currency_symbol: "USh" },
  { country: "Ukraine", currency_symbol: "₴" },
  { country: "United Arab Emirates", currency_symbol: "د.إ" },
  { country: "United Kingdom", currency_symbol: "£" },
  { country: "United States", currency_symbol: "$" },
  { country: "Uruguay", currency_symbol: "$" },
  { country: "Uzbekistan", currency_symbol: "so'm" },
  { country: "Vanuatu", currency_symbol: "Vt" },
  { country: "Vatican City", currency_symbol: "€" },
  { country: "Venezuela", currency_symbol: "Bs.S" },
  { country: "Vietnam", currency_symbol: "₫" },
  { country: "Yemen", currency_symbol: "ر.ي" },
  { country: "Zambia", currency_symbol: "K" },
  { country: "Zimbabwe", currency_symbol: "Z$" },
];
